.body-selector-image {
  width: 150px;
  height: 375px;
  margin: 0 auto;
  position: relative;
}

.body-selector-image img {
  position: absolute;
  width: 100%;
  height: 100%;
}

.range-indicator {
  position: absolute;
  bottom: -35px;
  transition: 0.4s ease-in-out;
  animation: leftToRight 2s infinite;
}

@keyframes leftToRight {
  0% {
    -webkit-transform: translate3d(5px, 0, 0);
    transform: translate3d(5px, 0, 0);
  }

  50% {
    -webkit-transform: translate3d(-5px, 0, 0);
    transform: translate3d(-5px, 0, 0);
  }

  100% {
    -webkit-transform: translate3d(5px, 0, 0);
    transform: translate3d(5px, 0, 0);
  }
}
