body,
html,
#root,
.page{
  height: 100%;
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

@media only screen and (max-width: 786px) {
  .hidden-mobile {
    display: none;
  }
}
